<template>
  <div class="page home">
    <HeadBar
      class="singer-headbar"
      title=""
      :isShowLogo="true"
      :isShowSinging="false"
      :isShowSetting="false"
      :isShowRight="true"
      :isSearch="false"
      :isShowSearchImg="true"
      :isShowTimesInfo="browserType === 'landscape'"
    />

    <div class="main-content">
      <!-- <div class="main-content-top">
        <PromoBanner />
        <div class="main-content-top-card horizontal">
          <NavList @click-nav="handleClickNav" />
        </div>
        <div class="main-content-top-mv">
          <MvMini v-if="isShowMini" />
        </div>
      </div>
      <NavList
        v-if="browserType === 'portrait'"
        class="vertical"
        @click-nav="handleClickNav"
      /> -->

      <GuessSonglist :pageSize="guessSonglistPageSize" from="index" />

      <img
        class="mic-operation horizontal"
        src="https://qncweb.ktvsky.com/20250109/vadd/ba34a8d5d4a34547d4e3433128fb688b.png"
        alt="运营位图片"
        @click="handleClickMic"
      />
      <img
        class="mic-operation vertical"
        src="https://qncweb.ktvsky.com/20250109/vadd/7ff3001f14c8c6cfb76c15e2d2feacc1.png"
        alt="运营位图片"
        @click="handleClickMic"
      />

      <div class="sheet-list" id="sheet-scroll">
        <div class="sheet-list-tab" id="refTab" ref="refTab">
          <div
            class="sheet-list-tab-item"
            :class="{ 'sheet-list-tab-active': curTab.id == item.id }"
            v-for="(item, index) in rankList"
            :key="item.id"
            @click="handleChangeTab(item)"
            :data-name="item.name"
          >
            {{ item.name }}
            <span v-if="index != 0"></span>
          </div>
        </div>
        <div class="diver-line"></div>
        <div class="sheet-list-content">
          <div class="sheet-list-content-cover">
            <img :src="curTab.image" v-img-fallback="imgFallback" />
            <p>{{ curTab.name }}</p>
          </div>
          <LoadMore
            class="song-list"
            :class="{ 'overflow-hidden': isOverflowHidden }"
            v-if="dataList.length"
            @load-more="fetchData"
            @watch-scroll="handleWatchScroll"
            safeAreaHeight="30vw"
          >
            <SongItem
              className="sheet-list-song-item"
              v-for="(songItem, index) in dataList"
              :key="index"
              :songItem="songItem"
              :index="index"
              :log-from="{
                song_list_source: curTab.name === '新歌速递' ? 15 : 3,
                song_list: curTab.name,
                song_list_id: curTab.id,
                fr1: curTab.name === '新歌速递' ? 1856 : 1803,
                fr2: curTab.name === '新歌速递' ? '新歌榜' : '歌单-歌单名称',
              }"
              :show1080Icon="curTab.id === 20179 || curTab.id === 3211"
              :showNewSongIcon="curTab.name === '新歌速递'"
              @order-click="watchOrderClick"
            />
            <p
              class="bot-hint"
              v-if="isShowEmpty || curTab.name === '猜你会唱'"
            >
              到底啦～
            </p>
          </LoadMore>
          <div v-if="!isRequest && !dataList.length" class="empty">
            暂无歌曲
          </div>
        </div>
      </div>
    </div>

    <!-- offline -->
    <!-- <RecommendSong :isActive="isShowRecommend" @close="closeRecommend" /> -->

    <SocialMindModal
      :isShow="isShowSocialMindModal"
      @sub="subSocialMindModal"
      @close="closeSocialMindModal"
    />
  </div>
</template>

<script setup>
import GuessSonglist from '@/components/guess-song/songlist.vue'
// import MvMini from '@/components/mv/mini.vue'
// import UserInfoTips from '@/components/user-info-tips/index.vue'
// import NavList from './components/nav/index.vue'

// import PromoBanner from '@/components/nav-list/promo-banner.vue'
import SongItem from '@/components/song-item/index.vue'
import SocialMindModal from '@/components/teleport/social/index.vue'
import { useShareBrowserSize } from '@/composables/sharedComposable'
import useActivity from '@/composables/useActivity'
import useLoginValidComposables from '@/composables/useLoginValid'
import useVip from '@/composables/useVip'
import { sendLog } from '@/directives/v-log/log'
import logParams from '@/log'
import { getHotSongList, getNewSongList } from '@/service/hotSongList'
import { getPlaylist, getPlaylistDetail } from '@/service/playlist'
import eventBus from '@/utils/event-bus'
import {
  getRecommendSongTag,
  getReduceRecommendSetting,
  setRecommendSongTag,
} from '@/utils/historyCache'
import { Toast } from 'vant'
import {
  computed,
  onActivated,
  onDeactivated,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const { browserType } = useShareBrowserSize()

const guessSonglistPageSize = computed(() =>
  browserType.value === 'landscape' ? 9 : 6
)

const generateGuessSongList = (oftenSingList, top50List) => {
  return oftenSingList.concat(top50List)

  // let guessSongList = []
  // let oftenSingIndex = 0
  // let top50Index = 0

  // while (
  //   oftenSingIndex < oftenSingList.length &&
  //   top50Index < top50List.length
  // ) {
  //   guessSongList.push(oftenSingList[oftenSingIndex])
  //   oftenSingIndex++

  //   if (oftenSingIndex < oftenSingList.length) {
  //     guessSongList.push(oftenSingList[oftenSingIndex])
  //     oftenSingIndex++
  //   }

  //   guessSongList.push(top50List[top50Index])
  //   top50Index++
  // }

  // guessSongList = guessSongList.concat(oftenSingList.slice(oftenSingIndex))
  // guessSongList = guessSongList.concat(top50List.slice(top50Index))

  // return guessSongList
}

const router = useRouter()
const store = useStore()
const { checkUserLoginStatus, showLoginQrcode } = useLoginValidComposables()
const { showActivityAiface, showActivityMic } = useActivity()
const unionid = computed(() => store.state.userInfo.unionid)
const isLogin = computed(() => !!store.state.userInfo.unionid)
const oftenSingList = computed(() => store.state.oftenSing.oftenSingList)
const top50List = computed(() => store.state.oftenSing.top50)
const isGuessSongList = computed(
  () => isLogin.value && oftenSingList.value.length
)
const userType = computed(() => store.state.userInfo.userType)
const isVip = computed(() => !!store.state.vipInfo.end_time)
const freeSing = computed(() => store.state.climax.freeSing)
const isShowMini = computed(() => store.state.miniMv.isShowMini)

const isShowEmpty = ref(false)

let curTab = ref({
  id: 0,
  name: '',
  img: '',
})
let p = 1
let version = ref({
  current: '',
  latest: '',
})
let isRequest = ref(false)
let rankList = ref([])
let dataList = ref([])
const refTab = ref(null)
const imgFallback = {
  loading:
    'https://qncweb.ktvsky.com/20210926/vadd/8a6ac4b746c04f4701481e4da1e146b3.png',
  error:
    'https://qncweb.ktvsky.com/20211231/vadd/f2fe8a149cd948ff5ef096597f47f965.png',
}
// const mvPageComponentRef = ref(null);

let isShowRecommend = ref(false)
let lastScrollPos = 0
// let timer = null;
// let isShowReduceRecommendModal = ref(false);
let reduceTimer = null

const { showVipQrcode } = useVip()

let isShowSocialMindModal = ref(false)

const showSocialMindModal = () => {
  isShowSocialMindModal.value = true
}

const closeSocialMindModal = () => {
  sendLog(logParams.get(30314)) // 预约关闭
  isShowSocialMindModal.value = false
}

const subSocialMindModal = () => {
  Toast({
    duration: 2000,
    message: '您已预约成功',
    position: 'center',
    className: `toast-defalut`,
  })
  isShowSocialMindModal.value = false
  sendLog(logParams.get(30313)) //预约成功
}

const handleChangeTab = (tab) => {
  if (curTab.value.id == tab.id) return

  const element = document.querySelector(`[data-name="${tab.name}"]`)
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    })
  }
  isShowEmpty.value = false

  sendLog({
    event_type: '10000~50000',
    event_name: 10065,
    event_data: {
      str1: '首页',
      str2: '歌单tab',
      str3: '切换歌单',
      str4: 'click',
      str5: tab.name || '',
      str7: tab.id || '',
    },
  })
  curTab.value = tab
  dataList.value = []
  p = 1
  version.value = {
    current: '',
    latest: '',
  }
  isRequest.value = false
  fetchData()

  sendLog({
    event_type: '10000~50000',
    event_name: curTab.value.name === '猜你会唱' ? 30009 : 30011,
    event_data: {
      str1: '首页',
      str2: curTab.value.name === '猜你会唱' ? '猜你会唱' : '歌单',
      str3:
        curTab.value.name === '猜你会唱'
          ? '猜你会唱列表展现'
          : '任意歌单列表展现',
      str4: 'show',
      str5: isLogin.value ? '已登录' : '未登录',
      str7: userType.value,
    },
  })
}

const initRankList = async () => {
  let res = await getPlaylist()
  if (res.length) {
    let filteredList = res.filter((v) => v.id !== 20179 || v.id !== 3211)
    const filter1080 = res.find((item) => item.id === 20179 || item.id === 3211)

    filteredList = filteredList.filter((v) => v.name !== '新歌速递')
    const specialNameItem = res.find((item) => item.name === '新歌速递')
    const childrenNameItem = res.find((item) => item.name === '儿童专区')

    if (filter1080) {
      filter1080.icon =
        // 'https://qncweb.ktvsky.com/20241224/vadd/ac1f06b6de858fdd0aa0415eccc54421.png'
        'https://qncweb.ktvsky.com/20240306/other/7ce38a00c035f2d83671132598ee848d.png'
      filteredList.unshift(filter1080)
    } //
    if (childrenNameItem) {
      childrenNameItem.icon =
        'https://qncweb.ktvsky.com/20240530/vadd/3d9551df79e0a2ca7a558099db99fae9.png'
    }

    if (specialNameItem) {
      specialNameItem.icon =
        'https://qncweb.ktvsky.com/20240325/vadd/0dee287a5f90cef20dc21af5a7b31dbb.png'
      const index1080 = filteredList.findIndex((item) => item.id === 20179)
      if (index1080 !== -1) {
        filteredList.splice(index1080 + 1, 0, specialNameItem)
      } else {
        filteredList.unshift(specialNameItem)
      }
    }

    filteredList = filteredList.slice(0, 10)

    rankList.value = [
      {
        name: '猜你会唱',
        image:
          'https://qncweb.ktvsky.com/20240521/other/a63dd979fba09ef898b4ecb1609e17d2.png',
      },
      ...filteredList,
    ]

    // console.log("rankList:", rankList.value);
    curTab.value = rankList.value[0]
    sendLog({
      event_type: '10000~50000',
      event_name: 30009,
      event_data: {
        str1: '首页',
        str2: '猜你会唱',
        str3: '猜你会唱列表展现',
        str4: 'show',
        str5: isLogin.value ? '已登录' : '未登录',
        str7: userType.value,
      },
    })
    fetchData()
  }
}

const requestBussinessData = async () => {
  let responseData = await getPlaylistDetail({
    p,
    type: curTab.value.name,
    version: version.value.latest,
  })
  return responseData
}

const requestHotSongData = async () => {
  const res = await getHotSongList({
    p,
    unionid: unionid.value,
    version: version.value.latest,
  })
  if (res.data.length) {
    if (p === 1 && res.version) {
      version.value = res.version
    }
    dataList.value = dataList.value.concat(res.data).filter((v, i) => i < 100)
    p++
  }
}

const requestNewSongList = async () => {
  const res = await getNewSongList({
    p,
    version: version.value.latest,
  })
  if (res.data.length) {
    if (p === 1 && res.version) {
      version.value = res.version
    }
    dataList.value = dataList.value.concat(res.data)
    p++
  }
}

const requestGuessSongList = () => {
  let guessSongList = isGuessSongList.value
    ? generateGuessSongList(oftenSingList.value, top50List.value)
    : top50List.value
  dataList.value = guessSongList
}

const fetchData = async () => {
  if (isRequest.value) {
    return
  }
  isRequest.value = true

  if (curTab.value.name === '热门歌曲，总有一首你会唱') {
    if (!dataList.value.length) await requestHotSongData()
    isRequest.value = false
    return
  }

  if (curTab.value.name === '猜你会唱') {
    if (!top50List.value.length) {
      await store.dispatch('oftenSing/initTop50List')
    }
    await requestGuessSongList()
    isRequest.value = false
    return
  }

  if (curTab.value.name === '新歌速递') {
    await requestNewSongList()
    isRequest.value = false
    return
  }

  // if (curTab.value.name === "中秋｜经典热歌") {
  //   dataList.value = top50List.value.filter(v => v.is_vip).map(t => ({
  //     ...t,
  //     sing_cnt: '',
  //   }));
  //   isRequest.value = false;
  //   return;
  // }

  const bussinessResponseData = await requestBussinessData()
  if (bussinessResponseData.data.length !== 0) {
    if (p === 1 && bussinessResponseData.version) {
      version.value = bussinessResponseData.version
    }
    dataList.value = dataList.value.concat(bussinessResponseData.data)
    p++
  }
  isRequest.value = false
  if (bussinessResponseData.data.length < 10) {
    isShowEmpty.value = true
  }
}

// const handleClickBanner = (index) => {
//   console.log('banner:', index)
//   if (index === 0) {
//     sendLog({
//       event_type: '10000~50000',
//       event_name: 30235,
//       event_data: {
//         str1: '首页',
//         str2: '"特别定制"歌单',
//         str3: '点击',
//         str4: 'click',
//         str5: isLogin.value ? '已登录' : '未登录',
//         str7: userType.value,
//       },
//     })
//     router.push({
//       name: 'songList',
//       query: {
//         name: '"特"别定制，会员专享歌单',
//         image:
//           'https://qncweb.ktvsky.com/common-web/operation_cms/1691133786343.png',
//       },
//     })
//   } else if (index === 1) {
//     console.log('aimv')
//     sendLog({
//       event_type: '10000~50000',
//       event_name: 20290,
//       event_data: {
//         str1: '首页',
//         str2: '换脸MV',
//         str3: '点击',
//         str4: 'click',
//       },
//     })
//     showActivityAiface({})
//   } else if (index === 2) {
//     console.log('climax')
//     try {
//       sendLog({
//         event_type: '10000~50000',
//         event_name: 30236,
//         event_data: {
//           str1: '首页',
//           str2: '快唱高潮',
//           str3: '点击',
//           str4: 'click',
//           str5: isLogin.value ? '已登录' : '未登录',
//           str7: userType.value,
//         },
//       })
//       if (!isLogin.value) {
//         showLoginQrcode()
//         return
//       }
//       if (isLogin.value && !isVip.value) {
//         if (freeSing.value) {
//           showVipQrcode({
//             log: `首页-底部运营位`,
//             isLogin: isLogin.value,
//             fr: 1759,
//           })
//           return
//         }
//         eventBus.emit('free-singing-control-popup', 'climax')
//         return
//       }
//       sendLog({
//         event_type: '10000~50000',
//         event_name: 30144,
//         event_data: {
//           str1: '首页',
//           str2: '快唱高潮',
//           str3: '快唱高潮点击',
//           str4: 'click',
//         },
//       })
//     } catch (e) {
//       console.log('climax error:', e)
//     }
//     router.push({
//       name: 'climax',
//     })
//   } else {
//     console.log('no event need to do')
//   }
// }

// const handleBannerChange = (e) => {
//   // console.log('banner change:', e, route.name)
//   if (e === 0 || route.name !== 'home') return

//   if (!mvIsHide.value) return

//   sendLog({
//     event_type: '10000~50000',
//     event_name: 30257,
//     event_data: {
//       str1: '首页',
//       str2: '轮播banner位-麦克风售卖',
//       str3: '展示',
//       str4: 'show',
//       // str5: isLogin.value ? '已登录' : '未登录',
//       // str7: userType.value,
//     },
//   })
// }

const showRecommend = () => {
  isShowRecommend.value = true
  setRecommendSongTag()

  // showReduceRecommendModal();
}

const closeRecommend = () => {
  isShowRecommend.value = false
}

// recommend active condition
const handleWatchScroll = (s) => {
  // console.log("handleWatchScroll", s);
  if (!getRecommendSongTag() && getReduceRecommendSetting() == 1) {
    if (Math.abs(lastScrollPos - s) > 150) {
      showRecommend()
    }
    lastScrollPos = s
  }
}

// recommend active condition
const watchOrderClick = () => {
  if (!getRecommendSongTag() && getReduceRecommendSetting() == 1) {
    showRecommend()
  }
}

const handleDelayShowRecommend = () => {
  reduceTimer = setTimeout(() => {
    showRecommend()
  }, 60 * 1000)
}
// 获取滚动值  设置是否可以滚动
const handleIsScroll = (val) => {
  isOverflowHidden.value = !val
}
const handleClickMic = () => {
  console.log('show mic modal~')
  showActivityMic()
  sendLog({
    event_type: '10000~50000',
    event_name: 30331,
    event_data: {
      str1: '销售麦克风',
      str2: '首页',
      str3: '点击',
      str4: 'click',
    },
  })
}

const abTestVersionTag = computed(() => store.state.abTest.abTestVersionTag)

const sendLogWithABTest = (logData) => {
  if (!abTestVersionTag.value) {
    // 如果 abTest 标签还未加载完成，等待加载
    const unwatch = watch(abTestVersionTag, (newVal, oldVal) => {
      if (newVal) {
        sendLog(logData)
        unwatch() // 清理 watch
      }
    })
  } else {
    // 如果已经加载完成，直接发送
    sendLog(logData)
  }
}

const isOverflowHidden = ref(true)

onMounted(() => {
  initRankList()
  // eventBus.on("handle-close-vip-qrcode", onCloseVipQrcode);
  eventBus.on('handle-show-home-recommend', handleDelayShowRecommend)
  eventBus.on('isScroll', handleIsScroll)

  // 选择减少推荐频次，首页60s后自动弹
  if (getReduceRecommendSetting() == 2) {
    handleDelayShowRecommend()
  }
})

onUnmounted(() => {
  if (reduceTimer) clearTimeout(reduceTimer)
  // eventBus.off("handle-close-vip-qrcode", onCloseVipQrcode);

  eventBus.off('handle-show-home-recommend', handleDelayShowRecommend)
  eventBus.off('isScroll', handleIsScroll)
})

onActivated(() => {
  store.commit('UPDATE_ISSHOWBOTTOMBAR', false)
  // console.log('onActivated')
  // store.commit(
  //   'UPDATE_MVCORETOP',
  //   browserType.value === 'landscape' ? 162 : 230
  // )

  sendLogWithABTest({
    event_type: '10000~50000',
    event_name: 10001,
    event_data: {
      str1: '首页',
      str2: '首页',
      str3: '进入首页',
      str4: 'show',
    },
  })
})

// 离开首页触发 store.commit('UPDATE_ISSHOWBOTTOMBAR', true)
onDeactivated(() => {
  store.commit('UPDATE_ISSHOWBOTTOMBAR', true)
})
</script>

<style lang="stylus" scoped>
.overflow-hidden
  overflow hidden
.home
  display flex
  flex-direction column
  // overflow scroll
  // padding 150px 80px 0 80px
  padding 0px 80px 0 80px
  height 100vh
  @media screen and (max-width 1200px) and (min-height 1200px)
    padding 0 0px 0px 64px

  .main-content
    display flex
    flex-direction column
    @media screen and (max-width 1200px) and (min-height 1200px)
      & > *
        padding-right 64px

    &-top
      width 100%
      height 420px
      margin-top 12px
      margin-bottom 48px
      display flex
      align-items center
      @media screen and (max-width 1200px) and (min-height 1200px)
        margin-top 0px
        height 378px
        margin-bottom 28px

      &-card
        width 552px
        height 420px

    ::v-deep .sec-gusse-sing
      .section-container-header-title
        position relative
        &::after
          content ''
          position absolute
          top 10px
          left 152px
          width 32px
          height 32px
          background url('https://qncweb.ktvsky.com/20241204/vadd/f41ee097239c1a65d271847789d98f6c.png') no-repeat
          background-size 100% 100%
          background-position center

    .mic-operation
      width 1760px
      height auto
      margin 0 0 48px 0
      @media screen and (max-width 1200px) and (min-height 1200px)
        margin-bottom 52px

    .sheet-list
      width 100%
      height calc(100vh - 170px)
      display flex
      flex-direction column
      position relative
      margin-left 0px
      margin-bottom 32px
      overflow hidden
      @media screen and (max-width 1200px) and (min-height 1200px)
        width 100%
        height 1070px
        margin-bottom 38px
        margin-left 0px
        padding-right 0px !important
      &-tab
        width 100%
        height 80px !important
        padding 0 0px
        display flex
        align-items flex-end
        overflow-x: scroll;
        overflow-y: visible
        margin-bottom 20px
        // border-bottom 2px solid #1D1D1F1A
        @media screen and (max-width 1200px) and (min-height 1200px)
          margin-bottom 52px
        &::-webkit-scrollbar
          display none
        &-item
          width auto
          height 80px
          line-height 80px
          display flex
          align-items center
          border-bottom none
          position relative
          padding 0 16px
          color rgba(29, 29, 31, 0.5)
          font-size var(--font-size-large)
          font-weight 400
          white-space nowrap
          margin-right 58px
          &:last-child
            margin-right 0px
          @media screen and (max-width 1200px) and (min-height 1200px)
            font-size 32px
          span
            position absolute
            width 2px
            height 30px
            background #1D1D1F1A
            left -28px
            top 50%
            margin-top -15px
        &-active
          color rgba(160, 74, 240, 1)
          border-bottom 2px solid rgba(160, 74, 240, 1)
          font-weight 700
        @media screen and (max-width 1200px) and (min-height 1200px)
          height 72px
          margin-bottom 28px
          &-item
            height 72px
            &-txt
              height 72px
              line-height 66px
              font-size 26px
      .diver-line
        width 100%
        height 2px
        background #1D1D1F1A
        // margin-bottom 20px
        position absolute
        top 79px
      &-content
        width 100%
        height calc(100vh - 360px)
        display flex
        justify-content space-between
        overflow hidden
        &-cover
          width 400px
          margin 20px 60px 0px 80px
          @media screen and (max-width 1200px) and (min-height 1200px)
            margin 26px 0px 0px 16px
            width 360px
          img
            width 400px
            height 400px
            border-radius var(--border-radius-small)
            @media screen and (max-width 1200px) and (min-height 1200px)
              width 360px
              height 360px
          p
            font-size var(--font-size-large)
            color rgba(29, 29, 31, 1)
            margin-top 20px
        .song-list
          flex 1
          height 100% !important
          padding-bottom 20px
          padding-right 0
          padding-left 30px
          &::-webkit-scrollbar
            display none
          @media screen and (max-width 1200px) and (min-height 1200px)
            padding 0 56px
        .loadmore
          padding-bottom 30px!important
        .empty
          flex 1
          margin-top 10vh
          font-size 28px
          color #1D1D1F80
          text-align center
        @media screen and (max-width 1200px) and (min-height 1200px)
          margin-top 0
          flex 1
          flex-direction row
          &-left
            width 300px
            display flex
            flex-direction column
            align-items top
            padding-left 0
            img
              width 240px
              height 240px
              margin-top 30px
            p
              margin-left 0
              max-width 240px
              text-align left
              color rgba(255, 255, 255, 0.7)
          &-right
            width 100%
            padding-left 0
            margin-top 0
            .song-list
              height 73.6vh !important
              padding 0 20px
              padding-bottom 0 !important
  .bot-hint
    font-size 24px
    color #1D1D1F80
    text-align center
    margin-top 80px
    width 100%
    text-align center
    @media screen and (max-width 1200px) and (min-height 1200px)
      margin-top 50px
</style>
